import React from "react";
import line from "../assets/images/line.svg";
import panel from "../assets/images/takeControlPanel.png";
import takeControlPanelDesktop from "../assets/images/takeControlPanelDesktop.png";
import "../assets/css/takeControl.css";

export const TakeControl = (data) => {
  const url = window.location.pathname;
  const phone = data.data.phoneNumber
  const phoneClaro = data.data.phoneNumberClaro
  return (
    <div className="takeControl">
      <h2>Tomá el control de tu seguridad</h2>
      <h3>DE UNA MANERA INTELIGENTE</h3>
      <div className="mobile">
        <img src={panel} alt="Panel" />
        <div className="indicators-list">
          <div className="indicator">
            <p className="number">1</p>
            <p className="panelInfo">
              Deslizá hacia abajo para configurar tu pantalla
            </p>
            <img src={line} alt="Divisory line" />
          </div>
          <div className="indicator">
            <p className="number">2</p>
            <p className="panelInfo">
              Visualizá la conexión Wi-fi, nivel de batería, etc
            </p>
            <img src={line} alt="Divisory line" />
          </div>
          <div className="indicator">
            <p className="number">3</p>
            <p className="panelInfo">Desactivá la pantalla táctil por 30´´</p>
            <img src={line} alt="Divisory line" />
          </div>
          <div className="indicator">
            <p className="number">4</p>
            <p className="panelInfo">Revisá las alertas del sistema</p>
            <img src={line} alt="Divisory line" />
          </div>
          <div className="indicator">
            <p className="number">5</p>
            <p className="panelInfo">Accedé al menú de configuraciones</p>
            <img src={line} alt="Divisory line" />
          </div>
        </div>
      </div>
      <div className="desktop">
        <img src={takeControlPanelDesktop} alt="Panel" />
      </div>
      {
        url === "/promo" ? (
          <a href={`tel:${phoneClaro}`}>
            <button>Quiero más información</button>
          </a>
        ):(
          <a href={`tel:${phone}`}>
            <button>Quiero más información</button>
          </a>
        )
      }
    </div>
  );
};
