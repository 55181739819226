import React from "react";
import location from "../assets/images/location.svg";
import phoneIcon from "../assets/images/phoneIcon.svg";
import facebook from "../assets/images/facebook.svg";
import "../assets/css/footer.css";

export const Footer = (data) => {
  const url = window.location.pathname;
  const phone = data.data.phoneNumber
  const numberIcon = data.data.phoneNumberIcon
  const phoneClaro = data.data.phoneNumberClaro
  const numberIconClaro = data.data.phoneNumberClaroIcon
  return (
    <div className="footer">
      <h2>CONTACTO</h2>
      <ul>
        <li>
          {" "}
          <img src={location} alt="Location" /> Argentina{" "}
        </li>
        { url==="/promo" ? (
          <li>
            {" "}
            <a href={`tel:${phoneClaro}`}>
              <img src={phoneIcon} alt="Phone" /> <b>{numberIconClaro}</b>
            </a>
          </li>
        ):(
          <li>
            {" "}
            <a href={`tel:${phone}`}>
              <img src={phoneIcon} alt="Phone" /> <b>{numberIcon}</b>
            </a>
          </li>
        )}
        <li>
          {" "}
          <div className="footer-meta-position">
            <img src={facebook} alt="Facebook" />
            <h6>
              Alarmas interactivas - Agente autorizado
            </h6>           
          </div>
        </li>
      </ul>
    </div>
  );
};
