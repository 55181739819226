import React from "react";
import icons from "../assets/images/smartPanelIcons.svg";
import smartPanel from "../assets/images/smartPanel.png";
import "../assets/css/smartPanel.css";

export const SmartPanel = () => {
  return (
    <div className="smartPanel">
      <h2>PANEL INTELIGENTE</h2>
      <p>La seguridad de tu hogar nunca fue tan fácil de administrar</p>
      <img src={smartPanel} alt="Smart Panel" />
      <ul>
        <li>
          <span>Panel táctil con conexión bluetooth a tu smartphone.</span>
        </li>
        <li>
          <span>Panel con Sirena y cámara IP incluidos.</span>
        </li>
        <li>
          <span>Alertas de emergencia.</span>
        </li>
        <li>
          <span>Biblioteca de tutoriales.</span>
        </li>
      </ul>
      <img src={icons} alt="Icons" />
    </div>
  );
};
