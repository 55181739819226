import React from "react";
import "../assets/css/floating.css";
import phoneIcon from "../assets/images/phoneIcon.svg";

export const Floating = (data) => {
  const url = window.location.pathname;
  const phone = data.data.phoneNumber
  const numberIcon = data.data.phoneNumberIcon
  const phoneClaro = data.data.phoneNumberClaro
  const numberIconClaro = data.data.phoneNumberClaroIcon
  return (
    <div className="floating-button" type="button">
      <img src={phoneIcon} alt="Phone" className="phoneIcon" />
      {url === "/promo" ? (
        <a href={`tel:${phoneClaro}`}>
        {window.innerWidth < 1001 ? (
          <div>
            <b>{numberIconClaro}</b>
          </div>
        ):(
          <div>
            <span>Consultá ahora </span> <b>{numberIconClaro}</b>
          </div>
        )}
        </a>
      ):(
        <a href={`tel:${phone}`}>
          {window.innerWidth < 1001 ? (
            <div>
              <b>{numberIcon}</b>
            </div>
          ):(
            <div>
              <span>Consultá ahora </span> <b>{numberIcon}</b>
            </div>
          )}
        </a>
      )}
    </div>
  );
};
