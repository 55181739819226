import React from "react";
import geoLocation from "../assets/images/geoLocation.svg";
import cloud from "../assets/images/cloud.svg";
import smartHome from "../assets/images/smartHome.svg";
import celphone from "../assets/images/celphone.svg";
import "../assets/css/smartSecurity.css";

export const SmartSecurity = () => {
  return (
    <div className="smartSecurity">
      <h2>SEGURIDAD INTELIGENTE</h2>
      <div className="items-list">
        <div className="item">
          <img src={geoLocation} alt="Geo Location" />
          <p>Geo-servicios</p>
        </div>
        <hr />
        <div className="item">
          <img src={cloud} alt="Cloud" />
          <p>Video inteligencia 24/7</p>
        </div>
        <hr />
        <div className="item">
          <img src={smartHome} alt="Smart Home" />
          <p>Automatización inteligente</p>
        </div>
        <hr />
        <div className="item">
          <img src={celphone} alt="celphone" />
          <p>Información en tiempo real</p>
        </div>
      </div>
    </div>
  );
};
