import Carousel from "react-bootstrap/Carousel";

import kitAlarma1 from "../assets/images/kitAlarma1.png";
import kitAlarma2 from "../assets/images/kitAlarma2.png";
import kitAlarma1Mobile from "../assets/images/kitAlarma1Mobile.png";
import kitAlarma2Mobile from "../assets/images/kitAlarma2Mobile.png";

import "../assets/css/slider.css";

export const SliderProducts = (data) => {
  const phone = data.data.phoneNumber
 
  return (
    <div className="slider-title">
      <div className="carousel">
        <Carousel >
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <a href={`tel:${phone}`}>
                <img
                  loading="lazy"
                  src={kitAlarma1Mobile}
                  alt="kitAlarma"
                  style={{ width: "100%", height: "140vw" }}
                />
              </a>
            ) : (
              <a href={`tel:${phone}`}>
                <img
                  loading="lazy"
                  src={kitAlarma1}
                  alt="kitAlarma"
                  className="d-block w-100"
                />
              </a>
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <a href={`tel:${phone}`}>
                <img
                  loading="lazy"
                  src={kitAlarma2Mobile}
                  alt="kitAlarma"
                  style={{ width: "100%", height: "140vw" }}
                />
              </a>
            ) : (
              <a href={`tel:${phone}`}>
                <img
                  loading="lazy"
                  src={kitAlarma2}
                  alt="kitAlarma"
                  className="d-block w-100"
                />
              </a>
            )}
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};
