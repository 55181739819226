import React, { useState, useEffect } from 'react'
import { Floating } from "./components/Floating";
import { Logo } from "./components/Logo";
import { Slider } from "./components/Slider";
import { SliderProducts } from "./components/SliderProducts";
import { SliderServices } from "./components/SliderServices";
import { Form } from "./components/Form";
import { SmartSecurity } from "./components/SmartSecurity";
import { SmartPanel } from "./components/SmartPanel";
import { TakeControl } from "./components/TakeControl";
import { OurApp } from "./components/OurApp";
import { Footer } from "./components/Footer";

import "./assets/css/App.css";

function App() {
  const url = window.location.pathname;
  const [data, setData] = useState(null);

  const fetchData = async () =>{
    const data = await fetch('landing-config.json')
    return data.json()
  }
  
  useEffect(() => {
    fetchData()
      .then((response) => {
        setData(response); // Store the resolved value in state
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      {data !== null ? (
        <div className="fullPage">
          <div className="top-bar">
            <Logo />
            <Floating data={data}/>
          </div>
          <div className="slider-position">
            {url === "/productos" ? (
              <SliderProducts data={data}/>
            ) : url === "/servicios" ? (
              <SliderServices data={data}/>
            ) : (
              <Slider data={data}/>
            )}
          </div>
          <div className="smartSecurity-position">
            <SmartSecurity />
          </div>
          <div className="form-position">
            <Form />
          </div>
          <div className="smartPanel-position">
            <SmartPanel />
          </div>
          <div className="takeControl-position">
            <TakeControl data={data}/>
          </div>
          <div className="ourApp-position">
            <OurApp />
          </div>
          <div className="footer-position">
            <Footer data={data}/>
          </div>
        </div>
    ):(<></>)}
    </>
  );
}

export default App;
