import React from "react";
import downloadApp from "../assets/images/downloadApp.svg";
import celphone from "../assets/images/celphoneMobile.png";
import celphoneDesktop from "../assets/images/celphoneDesktop.png";
import "../assets/css/ourApp.css";

export const OurApp = () => {
  const width = window.screen.width;

  return (
    <div className="ourApp">
      <div className="titles">
        <img src={downloadApp} alt="app" />
        <h1>Descargate la APP</h1>
        <h2>ADT interactive security</h2>
        <h3>y mantené tu hogar controlado</h3>
      </div>
      <div className="celphone-image">
        {width < 1001 ? (
          <img src={celphone} alt="Cel" />
        ) : (
          <img src={celphoneDesktop} alt="Cel" />
        )}
      </div>
    </div>
  );
};
